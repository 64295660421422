import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { motion, useAnimation } from 'framer-motion'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import NavLink from '../components/nav-link'
import { getNavItems } from '../utils/get-nav-items'
import scrollTo from '../utils/scroll-to'

const movReveal = {
  hidden: {
    opacity: 0,
    top: '-60px',
  },
  visible: {
    opacity: 1,
    top: '0',
    transition: {
      duration: 0.75,
      ease: 'easeOut',
    },
  },
}

interface Props {
  location: Location
  visible: boolean
}

const Header = ({ location, visible }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const controls = useAnimation()
  const navItems = getNavItems()
  const refCollapse = React.useRef(null)
  const refNavbar = React.useRef(null)

  const handleSelect = (eventKey) => {
    setExpanded(false)
    const isAnchor = new RegExp(`^${location.pathname}#`)
    isAnchor.test(eventKey) ? scrollTo(eventKey.slice(1)) : navigate(eventKey)
  }

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    controls.start(visible ? 'visible' : 'hidden')
    const classList = refCollapse?.current?.classList
    if (!(classList?.contains('show') || classList?.contains('collapsing'))){
      localStorage.setItem('site:navHeight', refNavbar?.current?.offsetHeight)
    }
  })

  return (
    <motion.div className="fixed-top" initial="hidden" animate={controls} variants={movReveal}>
      <Navbar expanded={expanded} onSelect={handleSelect} onToggle={handleToggle} id="header" bg="dark" variant="dark" expand="md" ref={refNavbar}>
        <Navbar.Toggle aria-controls="navbar-content" />
        <Navbar.Collapse id="navbar-content" ref={refCollapse}>
          <Nav activeKey={location.pathname} className="ps-3 me-auto">
            {navItems.map((item, index) => (
              <NavLink location to={item.to} key={index}>{item.label}</NavLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </motion.div>
  )
}

Header.defaultProps = {
  visible: true
}

export default Header
