import React from 'react'
import { Link } from 'gatsby'

import Nav from 'react-bootstrap/Nav';

interface Props {
  location: Location
  children: React.ReactNode
  to: string
}

const NavLink = ({ location, children, to, ...otherProps }: Props) => {
  const isAnchor = new RegExp(`^${location.pathname}#`)
  if (isAnchor) {
    const handleSelect = (eventKey, e) => {
        e.preventDefault()
    }
    const handleMouseLeave = (e) => {
      e.target.blur()
    }
    return (
      <Nav.Link href={to} onSelect={handleSelect} onMouseLeave={handleMouseLeave} {...otherProps}>
        {children}
      </Nav.Link>
    )
  }
  return (
    <Nav.Link as={Link} href={to} {...otherProps}>
      {children}
    </Nav.Link>
  )
}

export default NavLink
