import React from 'react'

import Navbar from 'react-bootstrap/Navbar';

import { useSiteMetadata } from '../utils/use-site-metadata'

import './footer.scss'

interface Props {
  author: string
}

const Footer = ({ author }: Props) => {
  const { siteAuthor } = useSiteMetadata()
  const thisAuthor = author ?? siteAuthor
  const notice = new Date().getFullYear() + (thisAuthor ? ` ${thisAuthor}` : '')
  return (
    <Navbar id="footer" className="justify-content-center" bg="dark" variant="dark">
      <Navbar.Text className="small">
        <span className="d-none d-lg-inline">Copyright </span>&copy; {notice}
      </Navbar.Text>
    </Navbar>
  )
}

export default Footer
